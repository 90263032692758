import { useState } from 'react'
import {v4 as v4uuid} from "uuid"
import { useParams } from 'react-router-dom';
import './index.css'

const SubServiceDetails = ()=>{
    const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const [number,setNumber] = useState("")
    const [tenure,setTenure] = useState("")
    const [area,setArea] = useState("")

    const onNameChange=(event)=>{
      event.preventDefault();
      setName(event.target.value);
    }
  
    const onMailChange=(event)=>{ 
      event.preventDefault();
      setEmail(event.target.value);
    }
    const onNumberChange=(event)=>{
      event.preventDefault();
      setNumber(event.target.value);
    }
    const onAreaChange=(event)=>{
        event.preventDefault();
        setArea(event.target.value);
      }
    const onTenureChange=(event)=>{
    event.preventDefault();
    setTenure(event.target.value);
    }

  
    const sendRequest =async (event)=>{
      event.preventDefault();
      var formDataList=[];
      if(area!=="" && tenure!==""){
        formDataList=[["name",name],["email",email],["number",number],["service",id],["tenure",tenure],["area",area]];
      }
      else{
        formDataList=[["name",name],["email",email],["number",number],["service",id]];
      }

  
      const formData = new FormData();
      
      formDataList.forEach(([key, value]) => {
        formData.append(key, value);
    });
  
      console.log(formDataList);
      formData.append("access_key", "0e06e85f-fe24-4fdd-b1ec-8117d89088f1");
      console.log(formData);
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      }).then((res) => res.json());
  
      if (res.success) {
        console.log("Success", res);
        
      } else {
        console.log("Error", res);
        
      }
      setEmail("");
      setName("");
      setNumber("");

    }; 
    const subServicesList = [{'id': v4uuid(), 'serviceCategory': 'AC', 'serviceTitle': 'SPLIT AC REPAIR SERVICES', 'serviceName': 'AC REPAIR & SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/SPLIT_AC_REPAIR_SERVICES.jpeg', 'serviceDescription': `SPLIT AC REPAIR SERVICES: Our Split AC Repair Services offer expert solutions for all your split air conditioning unit needs. Whether it's a malfunctioning compressor, faulty wiring, or general wear and tear, our skilled technicians are equipped to diagnose and repair any issue efficiently. We understand the importance of a properly functioning AC unit, especially during hot summer months, which is why we prioritize swift and effective repairs to restore comfort to your space. With our commitment to quality service and customer satisfaction, you can trust us to deliver reliable repairs that stand the test of time.`}
    ,{'id': v4uuid(), 'serviceCategory': 'AC', 'serviceTitle': 'COMMERCIAL INSTANT SERVICES', 'serviceName': 'AC REPAIR & SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/download.jpeg', 'serviceDescription': `COMMERCIAL INSTANT SERVICES: Our Commercial Instant Services provide quick and efficient solutions for businesses in need of immediate air conditioning assistance. Whether you're dealing with a sudden breakdown, temperature fluctuations, or other AC-related issues, our team is ready to respond promptly to minimize disruption to your operations. We understand the impact that a malfunctioning AC system can have on your business, which is why we prioritize fast turnaround times and effective solutions to get your commercial space back up and running smoothly. With our expertise and dedication to customer satisfaction, you can rely on us for reliable AC services tailored to meet your commercial needs.`}
    ,{'id': v4uuid(), 'serviceCategory': 'KEEPING', 'serviceTitle': 'HOUSE KEEPING SERVICES', 'serviceName': 'HOUSE KEEPING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/housekeeping-service.jpeg', 'serviceDescription': `HOUSE KEEPING SERVICES: Our housekeeping services are designed to meticulously maintain and enhance the cleanliness, organization, and overall appeal of residential properties, ensuring a welcoming and comfortable environment for occupants. From routine cleaning tasks to specialized deep cleaning projects, our dedicated team is committed to delivering top-notch service tailored to the unique needs of each home. With a focus on attention to detail and efficiency, we strive to exceed expectations and leave every space sparkling and refreshed.`}
,{'id': v4uuid(), 'serviceCategory': 'KEEPING', 'serviceTitle': 'OFFICE KEEPING SERVICES', 'serviceName': 'OFFICE KEEPING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/officekeepingservices.jpg', 'serviceDescription': `OFFICE KEEPING SERVICES: Our office keeping services are aimed at optimizing the cleanliness, orderliness, and functionality of workplace environments. We understand the importance of a clean and organized office in fostering productivity, professionalism, and employee well-being. Whether it's daily janitorial tasks, periodic deep cleaning, or specialized maintenance needs, our skilled team is equipped to deliver reliable and efficient service. We work closely with businesses to customize solutions that meet their specific requirements, ensuring a clean and conducive workspace for success.`}
,{'id': v4uuid(), 'serviceCategory': 'KEEPING', 'serviceTitle': 'HOTEL KEEPING SERVICES', 'serviceName': 'HOTEL KEEPING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/hotelkeepingservices.jpg', 'serviceDescription': `HOTEL KEEPING SERVICES: Our hotel keeping services are dedicated to maintaining the impeccable standards of cleanliness, comfort, and aesthetics that guests expect from hospitality establishments. We recognize the critical role of cleanliness in shaping the guest experience and strive to uphold the reputation and image of each hotel we serve. With a focus on professionalism, discretion, and attention to detail, our experienced team delivers comprehensive cleaning solutions tailored to the unique requirements of hotels of all sizes. From guest rooms to public areas, we are committed to providing a welcoming and hygienic environment that exceeds guest expectations.`}
,{'id': v4uuid(), 'serviceCategory': 'KEEPING', 'serviceTitle': 'VILLA KEEPING SERVICES', 'serviceName': 'VILLA KEEPING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/villakeepingservices.jpg', 'serviceDescription': `VILLA KEEPING SERVICES: Our villa keeping services are designed to preserve the luxury, comfort, and pristine condition of high-end residential properties. We understand the unique needs of villa owners who demand nothing but the best in terms of cleanliness and maintenance. Our dedicated team of professionals specializes in delivering personalized service to ensure that every aspect of the villa is meticulously cared for. Whether it's routine cleaning, seasonal maintenance, or specialized care for valuable assets, we provide tailored solutions to protect and enhance the value of each villa we manage.`}
,{'id': v4uuid(), 'serviceCategory': 'KEEPING', 'serviceTitle': 'RESTAURANT KEEPING SERVICES', 'serviceName': 'RESTAURANT KEEPING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/restaurantkeeping.jpg', 'serviceDescription': `RESTAURANT KEEPING SERVICES: Our restaurant keeping services are committed to creating clean, inviting, and hygienic dining environments that enhance the overall guest experience. We recognize the importance of cleanliness and sanitation in upholding food safety standards, preserving the reputation of the establishment, and ensuring customer satisfaction. Our experienced team of professionals offers comprehensive cleaning solutions tailored to the unique needs of restaurants, bars, cafes, and other dining establishments. From kitchen cleaning to dining area maintenance, we work diligently to exceed industry standards and support the success of our clients in the hospitality sector.`}
    ,{'id': v4uuid(), 'serviceCategory': 'AC', 'serviceTitle': 'AC SERVICES', 'serviceName': 'AC REPAIR & SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/AC_SERVICES.jpeg', 'serviceDescription': `AC SERVICES: Our AC Services offer comprehensive solutions for all your air conditioning needs, ensuring optimal performance and efficiency of your cooling systems. From installation to maintenance and repairs, our experienced technicians are committed to delivering top-notch service that exceeds your expectations. Whether you're looking to upgrade to a new AC unit, schedule routine maintenance to prolong the lifespan of your system, or address any issues that may arise, we've got you covered. With our focus on quality workmanship and customer satisfaction, you can trust us to keep your home or business cool and comfortable year-round.`}
    ,{'id': v4uuid(), 'serviceCategory': 'AC', 'serviceTitle': 'AC INSTALLATION & UNINSTALLATION SERVICES', 'serviceName': 'AC REPAIR & SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/AC_INSTALLATION_&_UNINSTALLATION_SERVICES.jpeg', 'serviceDescription': `AC INSTALLATION & UNINSTALLATION SERVICES: Our AC Installation & Uninstallation Services provide efficient and reliable solutions for installing or removing air conditioning units with precision and care. Whether you're upgrading to a new system, relocating your existing unit, or need professional assistance with installation or uninstallation, our skilled technicians are here to help. We understand the importance of proper installation to ensure optimal performance and longevity of your AC unit, which is why we prioritize attention to detail and adherence to manufacturer guidelines. With our expertise and commitment to customer satisfaction, you can trust us to handle your AC installation and uninstallation needs with professionalism and efficiency.`}
    ,{'id': v4uuid(), 'serviceCategory': 'AC', 'serviceTitle': 'AC Repair & Service', 'serviceName': 'AC REPAIR & SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/AC_Repair_&_Service.jpeg', 'serviceDescription': `AC Repair & Service: Our AC Repair & Service offers professional repair and maintenance solutions for all types of air conditioning systems, ensuring optimal performance and efficiency. Whether you're dealing with a minor issue like a clogged filter or a major problem like a malfunctioning compressor, our experienced technicians have the knowledge and expertise to diagnose and fix the problem promptly. We understand the importance of a properly functioning AC unit, especially during hot summer months, which is why we prioritize fast turnaround times and effective solutions to restore comfort to your home or business. With our commitment to quality service and customer satisfaction, you can trust us to keep your AC system running smoothly year-round.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'AC', 'serviceTitle': 'Chimney Repair', 'serviceName': 'AC REPAIR & SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/Chimney_Repair.jpeg', 'serviceDescription': `Chimney Repair: Our Chimney Repair services provide swift solutions for chimney issues, ensuring the safety and functionality of your fireplace. Whether you're dealing with a damaged chimney cap, cracked flue liner, or deteriorating mortar joints, our experienced technicians have the expertise to assess the problem and recommend the appropriate repairs. We understand the importance of a well-maintained chimney for proper ventilation and smoke removal, which is why we prioritize timely repairs to prevent further damage and ensure the longevity of your chimney. With our commitment to quality workmanship and customer satisfaction, you can trust us to restore your chimney to optimal condition.`}
    ,{'id': v4uuid(), 'serviceCategory': 'AC', 'serviceTitle': 'Refrigerator Repair', 'serviceName': 'AC REPAIR & SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/Refrigerator_Repair.jpeg', 'serviceDescription': `Refrigerator Repair: Our Refrigerator Repair services offer reliable solutions for all your refrigerator-related issues, ensuring the proper functioning and longevity of your appliance. Whether you're dealing with a faulty compressor, leaking coolant, or a malfunctioning thermostat, our skilled technicians have the knowledge and expertise to diagnose and fix the problem efficiently. We understand the importance of a fully operational refrigerator for preserving food and maintaining a healthy lifestyle, which is why we prioritize fast turnaround times and effective solutions to restore functionality to your appliance. With our commitment to quality service and customer satisfaction, you can trust us to keep your refrigerator running smoothly.`}
    ,{'id': v4uuid(), 'serviceCategory': 'AC', 'serviceTitle': 'Washing Machine Repair', 'serviceName': 'AC REPAIR & SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/Washing_Machine_Repair.jpeg', 'serviceDescription': `Washing Machine Repair: Our Washing Machine Repair services provide expert solutions for all your washing machine issues, ensuring the proper functioning and longevity of your appliance. Whether you're dealing with a leaking washer, a broken agitator, or a malfunctioning control panel, our experienced technicians have the skills and expertise to diagnose and fix the problem promptly. We understand the importance of a fully functional washing machine for maintaining cleanliness and convenience in your home, which is why we prioritize fast turnaround times and effective solutions to restore functionality to your appliance. With our commitment to quality service and customer satisfaction, you can trust us to keep your washing machine running smoothly.`}
    ,{'id': v4uuid(), 'serviceCategory': 'AC', 'serviceTitle': 'Water Purifier', 'serviceName': 'AC REPAIR & SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/Water_Purifier.jpeg', 'serviceDescription': `Water Purifier: Our Water Purifier services offer quality service for water purifiers, ensuring clean and safe drinking water for your home or business. Whether you need installation, maintenance, or repair services for your water purification system, our experienced technicians are here to help. We understand the importance of clean water for your health and well-being, which is why we prioritize attention to detail and thorough service to ensure the optimal performance of your water purifier. With our commitment to quality workmanship and customer satisfaction, you can trust us to provide reliable solutions tailored to your specific needs.`}
    ,{'id': v4uuid(), 'serviceCategory': 'AC', 'serviceTitle': 'Microwave', 'serviceName': 'AC REPAIR & SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/Microwave.jpeg', 'serviceDescription': `Microwave: Our Microwave repair services offer fast and effective solutions for all your microwave-related issues, ensuring the proper functioning and longevity of your appliance. Whether you're dealing with a broken door latch, a malfunctioning keypad, or a faulty heating element, our skilled technicians have the expertise to diagnose and fix the problem efficiently. We understand the importance of a fully operational microwave for convenience and efficiency in your kitchen, which is why we prioritize fast turnaround times and effective solutions to restore functionality to your appliance. With our commitment to quality service and customer satisfaction, you can trust us to keep your microwave running smoothly.`}
    ,{'id': v4uuid(), 'serviceCategory': 'AC', 'serviceTitle': 'Geyser Repair', 'serviceName': 'AC REPAIR & SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/Geyser_Repair.jpeg', 'serviceDescription': `Geyser Repair: Our Geyser Repair services provide expert solutions for all your geyser-related issues, ensuring hot water on demand for your home or business. Whether you're dealing with a leaking tank, a faulty thermostat, or a broken heating element, our experienced technicians have the skills and expertise to diagnose and fix the problem promptly. We understand the importance of a fully functional geyser, especially during cold winter months, which is why we prioritize fast turnaround times and effective solutions to restore functionality to your appliance. With our commitment to quality service and customer satisfaction, you can trust us to keep your geyser running smoothly.`}
    ,{'id': v4uuid(), 'serviceCategory': 'ELECTRICAL', 'serviceTitle': 'APARTMENT ELECTRICAL SERVICE', 'serviceName': 'ELECTRICAL SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/APARTMENT_ELECTRICAL_SERVICE.jpeg', 'serviceDescription': `APARTMENT ELECTRICAL SERVICE: Our Apartment Electrical Service offers tailored solutions for electrical needs in apartment complexes, ensuring safety and reliability for residents. Whether you're looking to install new electrical fixtures, upgrade wiring, or troubleshoot electrical issues, our experienced electricians are here to help. We understand the unique challenges of apartment living, which is why we prioritize attention to detail and adherence to safety standards to deliver quality workmanship that meets your specific requirements. With our commitment to customer satisfaction and reliable service, you can trust us to handle all your apartment electrical needs with professionalism and efficiency.`}
    ,{'id': v4uuid(), 'serviceCategory': 'ELECTRICAL', 'serviceTitle': 'SHOPS ELECTRICAL SERVICE', 'serviceName': 'ELECTRICAL SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/SHOPS_ELECTRICAL_SERVICE.jpeg', 'serviceDescription': `SHOPS ELECTRICAL SERVICE: Our Shops Electrical Service provides comprehensive solutions for electrical needs in retail establishments, ensuring smooth operations and safety for employees and customers. Whether you're setting up a new shop, renovating an existing space, or in need of maintenance services, our experienced electricians have the expertise to handle any project with precision and care. We understand the importance of reliable electrical systems for powering essential equipment and lighting displays, which is why we prioritize efficient service and attention to detail to meet your specific requirements. With our commitment to quality workmanship and customer satisfaction, you can trust us to keep your shop powered up and running smoothly.`}
    ,{'id': v4uuid(), 'serviceCategory': 'ELECTRICAL', 'serviceTitle': 'SHOWROOM ELECTRICAL SERVICE', 'serviceName': 'ELECTRICAL SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/HOME_ELECTRICAL_SERVICE.jpeg', 'serviceDescription': `SHOWROOM ELECTRICAL SERVICE: Our Showroom Electrical Service offers specialized solutions for electrical needs in showroom spaces, ensuring optimal functionality and aesthetics for showcasing your products. Whether you're setting up a new showroom, upgrading lighting fixtures, or in need of maintenance services, our experienced electricians are here to help. We understand the importance of well-designed and properly illuminated spaces for attracting customers and enhancing the shopping experience, which is why we prioritize attention to detail and adherence to safety standards to deliver quality workmanship that exceeds your expectations. With our commitment to customer satisfaction and reliable service, you can trust us to handle all your showroom electrical needs with professionalism and expertise.`}
    ,{'id': v4uuid(), 'serviceCategory': 'ELECTRICAL', 'serviceTitle': 'HOME ELECTRICAL SERVICE', 'serviceName': 'ELECTRICAL SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/HOME_ELECTRICAL_SERVICE2.jpeg', 'serviceDescription': `HOME ELECTRICAL SERVICE: Our Home Electrical Service provides comprehensive solutions for electrical needs in residential properties, ensuring safety, comfort, and efficiency for homeowners. Whether you're building a new home, renovating an existing space, or in need of repairs and maintenance, our experienced electricians have the skills and expertise to handle any project with precision and care. We understand the importance of reliable electrical systems for powering appliances, lighting, and entertainment devices, which is why we prioritize attention to detail and adherence to safety standards to deliver quality workmanship that meets your specific needs. With our commitment to customer satisfaction and reliable service, you can trust us to keep your home powered up and running smoothly.`}
    ,{'id': v4uuid(), 'serviceCategory': 'ELECTRICAL', 'serviceTitle': 'ELECTRICIAN SERVICES', 'serviceName': 'ELECTRICAL SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/ELECTRICIAN_SERVICES.jpeg', 'serviceDescription': `ELECTRICIAN SERVICES: Our Electrician Services offer a wide range of electrical solutions for both residential and commercial properties, ensuring safety, reliability, and efficiency. Whether you need wiring installations, lighting upgrades, panel upgrades, or troubleshooting and repairs, our skilled electricians are here to help. We understand the importance of properly functioning electrical systems for powering essential equipment and appliances, which is why we prioritize attention to detail and adherence to safety standards to deliver quality workmanship that exceeds your expectations. With our commitment to customer satisfaction and reliable service, you can trust us to handle all your electrical needs with professionalism and expertise.`}
    ,{'id': v4uuid(), 'serviceCategory': 'PLUMBING', 'serviceTitle': 'HOME PLUMBING SERVICE', 'serviceName': 'PLUMBING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/HOME_PLUMBING_SERVICE.jpeg', 'serviceDescription': `HOME PLUMBING SERVICE: Our Home Plumbing Service provides expert solutions for all your residential plumbing needs, ensuring functionality, efficiency, and comfort. Whether you're dealing with leaky faucets, clogged drains, or water heater issues, our experienced plumbers have the skills and expertise to diagnose and fix the problem promptly. We understand the importance of a properly functioning plumbing system for daily tasks like cooking, cleaning, and bathing, which is why we prioritize fast turnaround times and effective solutions to restore functionality to your home. With our commitment to quality service and customer satisfaction, you can trust us to keep your plumbing system running smoothly.`}


    ,{'id': v4uuid(), 'serviceCategory': 'Salon', 'serviceTitle': 'Basic Wash', 'serviceName': 'Basic Wash', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/salonForWomen.jpg', 'serviceDescription': 'Unleash your beauty, indulge in luxury.'}
    ,{'id': v4uuid(), 'serviceCategory': 'Salon', 'serviceTitle': 'Foam Wash', 'serviceName': 'Foam Wash', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/salonForMen.jpg', 'serviceDescription': 'From little ones to grown gents, grooming made great.'}
    ,{'id': v4uuid(), 'serviceCategory': 'Salon', 'serviceTitle': 'Polishing', 'serviceName': 'Polishing', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/salonForWomen.jpg', 'serviceDescription': 'Unleash your beauty, indulge in luxury.'}
    ,{'id': v4uuid(), 'serviceCategory': 'Salon', 'serviceTitle': 'Shining Wash', 'serviceName': 'Shining Wash', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/salonForMen.jpg', 'serviceDescription': 'From little ones to grown gents, grooming made great.'}

    ,{'id': v4uuid(), 'serviceCategory': 'home', 'serviceTitle': 'Home Painting', 'serviceName': 'Home Painting', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/painting.jpeg', 'serviceDescription': `Home Painting: Transform your living space with our professional home painting services. Whether you're looking to refresh a single room or completely revamp your entire home, our team of experienced painters delivers impeccable results with attention to detail and a commitment to quality craftsmanship. From selecting the perfect color palette to executing flawless paint application, we turn your vision into reality, creating a vibrant and inviting atmosphere that reflects your personal style and enhances the beauty of your home.`}
    ,{'id': v4uuid(), 'serviceCategory': 'PLUMBING', 'serviceTitle': 'SHOPS PLUMBING SERVICE', 'serviceName': 'PLUMBING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/SHOPS_PLUMBING_SERVICE.jpeg', 'serviceDescription': `SHOPS PLUMBING SERVICE: Our Shops Plumbing Service offers specialized solutions for plumbing needs in retail establishments, ensuring smooth operations and customer satisfaction. Whether you're setting up a new shop, renovating an existing space, or in need of maintenance services, our experienced plumbers are here to help. We understand the unique challenges of commercial plumbing, which is why we prioritize efficient service and attention to detail to meet your specific requirements. With our commitment to quality workmanship and customer satisfaction, you can trust us to keep your shop's plumbing system in top condition.`}

    ,{'id': v4uuid(), 'serviceCategory': 'PLUMBING', 'serviceTitle': 'SHOWROOM PLUMBING SERVICE', 'serviceName': 'PLUMBING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/SHOWROOM_PLUMBING_SERVICE.jpeg', 'serviceDescription': `Showroom Plumbing Service: Ensure your showroom stays pristine and fully functional with our professional plumbing service. From fixing leaks to installing new fixtures, we guarantee top-notch craftsmanship that reflects the professionalism of your business.`}
    ,{'id': v4uuid(), 'serviceCategory': 'PLUMBING', 'serviceTitle': 'APARTMENT PLUMBING SERVICE', 'serviceName': 'PLUMBING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/APARTMENT_PLUMBING_SERVICE.jpeg', 'serviceDescription': `Apartment Plumbing Service: In your apartment, comfort and convenience are paramount. Our plumbing service ensures your plumbing systems work seamlessly, providing you with peace of mind and a comfortable living space for you and your family.`}
    ,{'id': v4uuid(), 'serviceCategory': 'PLUMBING', 'serviceTitle': 'PLUMBER SERVICE', 'serviceName': 'PLUMBING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/HOME_PLUMBING_SERVICE.jpeg', 'serviceDescription': `Plumber Service: Our expert plumbers are equipped to handle any plumbing issue with efficiency and expertise. From minor repairs to major installations, trust us to deliver reliable solutions promptly and professionally.'
`}
    ,{'id': v4uuid(), 'serviceCategory': 'CARPENTRY WORK', 'serviceTitle': 'CUPBOARD WORK SERVICES', 'serviceName': 'CARPENTRY WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/CUPBOARD_DESIGNING_SERVICES.jpeg', 'serviceDescription': `Cupboard Work Services: Transform your spaces with our cupboard work services. Whether you need custom-built closets or storage solutions tailored to your needs, our craftsmen deliver exceptional quality and functionality.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'CARPENTRY WORK', 'serviceTitle': 'WOOD WORK SERVICES', 'serviceName': 'CARPENTRY WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/WOOD_WORK_SERVICES.jpeg', 'serviceDescription': `Wood Work Services: Experience the timeless beauty and durability of wood with our woodwork services. From intricate furniture pieces to elegant trimmings, our skilled artisans bring out the best in wood craftsmanship.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'CARPENTRY WORK', 'serviceTitle': 'MODULAR KITCHEN WORK', 'serviceName': 'CARPENTRY WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/MODULAR_KITCHEN_WORK.jpeg', 'serviceDescription': `Modular Kitchen Work: Revamp your kitchen with our modular kitchen work. We blend style and functionality to create modern culinary spaces that cater to your specific needs and preferences.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'RAILING WORK', 'serviceTitle': 'ELIVATION RALING WORK SERVICES', 'serviceName': 'RAILING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/ELIVATION_RALING_WORK_SERVICES.jpeg', 'serviceDescription': `Elevation Railing Work Services: Enhance the safety and aesthetics of your property with our elevation railing work services. Our durable and stylish railings not only provide security but also add architectural charm to your building facade.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'RAILING WORK', 'serviceTitle': 'GATES RAILING WORK SERVICES', 'serviceName': 'RAILING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/GATES_RAILING_WORK_SERVICES.jpeg', 'serviceDescription': `Gates Railing Work Services: Secure your property with our gates railing work services. From ornate designs to sleek, minimalist styles, we offer custom solutions that combine security with elegance for your gates and fences.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'RAILING WORK', 'serviceTitle': 'TABLE AND CHAIR DESIGN SERVICES', 'serviceName': 'RAILING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/TABLE_AND_CHAIR_DESIGN_SERVICES.jpeg', 'serviceDescription': `Table and Chair Design Services: Impress your guests with bespoke table and chair designs that reflect your unique taste and style. Our design experts craft functional yet stylish pieces that elevate any dining or workspace.`}
    ,{'id': v4uuid(), 'serviceCategory': 'RAILING WORK', 'serviceTitle': 'SHUTTER WORK SERVICES', 'serviceName': 'RAILING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/SHUTTER_WORK_SERVICES.jpeg', 'serviceDescription': `Shutter Work Services: Protect your windows and add a touch of sophistication to your interiors with our shutter work services. From classic wooden shutters to modern designs, we offer customizable solutions to suit your preferences.`}
    ,{'id': v4uuid(), 'serviceCategory': 'RAILING WORK', 'serviceTitle': 'CUSTOMIZED HOTEL TABLES SERVICES', 'serviceName': 'RAILING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/CUSTOMIZED_HOTEL_TABLES_SERVICES.jpeg', 'serviceDescription': `Customized Hotel Tables Services: Create a welcoming ambiance in your hotel with our customized table services. Whether it's for dining, lounging, or working, our tables are designed to enhance the guest experience and complement your hotel's aesthetic.`}
    ,{'id': v4uuid(), 'serviceCategory': 'FLOORING WORK', 'serviceTitle': 'WOODEN FLOOR LAYING SERVICES', 'serviceName': 'FLOORING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/WOODEN_FLOOR_LAYING_SERVICES.jpeg', 'serviceDescription': `Wooden Floor Laying Services: Experience the warmth and beauty of wooden floors with our expert laying services. Our skilled craftsmen ensure precise installation, resulting in floors that are not only visually stunning but also durable and long-lasting.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'FLOORING WORK', 'serviceTitle': 'MARBLE AND GRANITE LAYING SERVICES', 'serviceName': 'FLOORING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/MARBLE_AND_GRANITE_LAYING_SERVICES.jpeg', 'serviceDescription': `Marble and Granite Laying Services: Transform your spaces with the timeless elegance of marble and granite. Our laying services ensure flawless installation, highlighting the natural beauty and durability of these luxurious materials, adding a touch of sophistication to your interiors.`}
    ,{'id': v4uuid(), 'serviceCategory': 'SOFA CLEANING', 'serviceTitle': 'SOFA CLEANING', 'serviceName': 'SOFA CLEANING', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/allServices/sofa.jpg', 'serviceDescription': `Sofa Cleaning: Restore the beauty and freshness of your sofas with our professional cleaning service. Using advanced techniques and eco-friendly products, we remove dirt, stains, and allergens, leaving your sofas looking and feeling like new.`}
    ,{'id': v4uuid(), 'serviceCategory': 'LAUNDRY', 'serviceTitle': 'LAUNDRY SERVICES', 'serviceName': 'LAUNDRY SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/allServices/laundry.jpg', 'serviceDescription': `Laundry Services: Experience the convenience of hassle-free laundry with our comprehensive services. From washing and folding to ironing and dry cleaning, we handle all your laundry needs with efficiency and care, so you can enjoy more free time.`}
    ,{'id': v4uuid(), 'serviceCategory': 'WINDOW CLEANING', 'serviceTitle': 'WINDOW CLEANING', 'serviceName': 'WINDOW CLEANING', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/allServices/window.jpg', 'serviceDescription': `Window Cleaning: Let natural light shine through pristine windows with our expert cleaning service. Our skilled team ensures streak-free results, enhancing the clarity of your views and the overall appearance of your property.`}
    ,{'id': v4uuid(), 'serviceCategory': 'PEST CONTROL', 'serviceTitle': 'RATS PEST CONTROL', 'serviceDescription': 'Access top-quality pest control solutions tailored to your schedule and needs.', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/allServices/pest.jpg'}
    ,{'id': v4uuid(), 'serviceCategory': 'PEST CONTROL', 'serviceTitle': 'FLIES PEST CONTROL', 'serviceDescription': 'Access top-quality pest control solutions tailored to your schedule and needs.', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/allServices/pest.jpg'}
    ,{'id': v4uuid(), 'serviceCategory': 'PEST CONTROL', 'serviceTitle': 'WOODWORMS PEST CONTROL', 'serviceDescription': 'Access top-quality pest control solutions tailored to your schedule and needs.', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/allServices/pest.jpg'}
    ,{'id': v4uuid(), 'serviceCategory': 'PEST CONTROL', 'serviceTitle': 'COCKROACHES PEST CONTROL', 'serviceDescription': 'Access top-quality pest control solutions tailored to your schedule and needs.', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/allServices/pest.jpg'}
        ,{'id': v4uuid(), 'serviceCategory': 'CCTV', 'serviceTitle': 'CCTV SERVICES', 'serviceName': 'CCTV SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/allServices/cctv.jpg', 'serviceDescription': `CCTV Services: Ensure the security of your property with our CCTV services. We offer installation, maintenance, and monitoring solutions tailored to your specific needs, providing you with peace of mind and 24/7 surveillance.`}
    ,{'id': v4uuid(), 'serviceCategory': 'MOTOR', 'serviceTitle': 'MOTOR SERVICES', 'serviceName': 'MOTOR SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/allServices/motor.jpg', 'serviceDescription': `Motor Services: Keep your motors running smoothly with our professional maintenance and repair services. Whether it's for vehicles, machinery, or equipment, our expert technicians ensure optimal performance and longevity.`}
    ,{'id': v4uuid(), 'serviceCategory': 'FLOORING WORK', 'serviceTitle': 'TILES LAYING SERVICES', 'serviceName': 'FLOORING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/TILES_LAYING_SERVICES.jpeg', 'serviceDescription': `Tiles Laying Services: Upgrade your floors, walls, and surfaces with our tiles laying services. From ceramic and porcelain to marble and mosaic, we offer a wide range of options and impeccable craftsmanship, transforming your spaces with style and durability.`}
    ,{'id': v4uuid(), 'serviceCategory': 'CEILING WORK', 'serviceTitle': 'POP CEILING WORK SERVICES', 'serviceName': 'CEILING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/POP_CEILING_WORK_SERVICES.jpeg', 'serviceDescription': `POP Ceiling Work Services: Add architectural interest to your ceilings with our POP (Plaster of Paris) ceiling work services. From intricate designs to modern textures, we create custom ceilings that enhance the aesthetics and character of your interiors.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'CEILING WORK', 'serviceTitle': 'PVC CEILING WORK SERVICES', 'serviceName': 'CEILING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/PVC_CEILING_WORK_SERVICES.jpeg', 'serviceDescription': `PVC Ceiling Work Services: Achieve a sleek and modern look with our PVC ceiling work services. Durable, lightweight, and easy to maintain, PVC ceilings are a practical and stylish choice for residential and commercial spaces alike.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'CEILING WORK', 'serviceTitle': 'WPUC CEILING WORK SERVICES', 'serviceName': 'CEILING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/WPUC_CEILING_WORK_SERVICES.jpeg', 'serviceDescription': `WPUC Ceiling Work Services: Experience the beauty and versatility of wood plastic composite (WPUC) ceilings with our expert installation services. Combining the natural appeal of wood with the durability of plastic, WPUC ceilings offer timeless elegance and long-lasting performance.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'CEILING WORK', 'serviceTitle': 'GYPSUM CEILING WORK SERVICES', 'serviceName': 'CEILING WORK SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/GYPSUM_CEILING_WORK_SERVICES.jpeg', 'serviceDescription': `Gypsum Ceiling Work Services: Transform your ceilings with the versatility and creativity of gypsum. Our gypsum ceiling work services offer endless design possibilities, from simple and elegant to intricate and ornate, adding visual interest and sophistication to your interiors.
`}
   ,{'id': v4uuid(), 'serviceCategory': 'CLEANING', 'serviceTitle': 'BATHROOM CLEANING SERVICES', 'serviceName': 'CLEANING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/BATHROOM_CLEANING_SERVICES.jpeg', 'serviceDescription': `Bathroom Cleaning Services: Refresh and sanitize your bathrooms with our comprehensive cleaning services. From scrubbing tiles to disinfecting surfaces, we ensure a hygienic and inviting space that promotes cleanliness and well-being.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'CLEANING', 'serviceTitle': 'RESTAURANT CHAIR CLEANING SERVICE', 'serviceName': 'CLEANING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/RESTAURANT_CHAIR_CLEANING_SERVICE.jpeg', 'serviceDescription': `Restaurant Chair Cleaning Service: Maintain a clean and inviting atmosphere in your restaurant with our chair cleaning service. Our thorough cleaning methods remove stains, spills, and odors, ensuring your chairs look fresh and inviting for your patrons.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'CLEANING', 'serviceTitle': 'KITCHEN CLEANING SERVICE', 'serviceName': 'CLEANING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/KITCHEN_CLEANING_SERVICE.jpeg', 'serviceDescription': `Kitchen Cleaning Service: Keep your kitchen spotless and hygienic with our professional cleaning service. From countertops to appliances, our meticulous cleaning process eliminates grease, grime, and bacteria, creating a safe and sanitary food preparation area.`}
    ,{'id': v4uuid(), 'serviceCategory': 'CLEANING', 'serviceTitle': 'CAR CLEANING SERVICES', 'serviceName': 'CLEANING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/CAR_CLEANING_SERVICES.jpg', 'serviceDescription': `Car Cleaning Service: Give your car a fresh start with our car cleaning service. Our experienced team tackles every corner and service, leaving your car immaculate and ready for you to ride.`}
    ,{'id': v4uuid(), 'serviceCategory': 'CLEANING', 'serviceTitle': 'HOME DEEP CLEANING SERVICE', 'serviceName': 'CLEANING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/HOME_DEEP_CLEANING_SERVICE.jpeg', 'serviceDescription': `Home Deep Cleaning Service: Give your home a fresh start with our deep cleaning service. Our experienced team tackles every corner and crevice, leaving your home immaculate and ready for you to enjoy.`}
    ,{'id': v4uuid(), 'serviceCategory': 'CLEANING', 'serviceTitle': 'DUPLEX HOUSE DEEP CLEANING SERVICES', 'serviceName': 'CLEANING SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/DUPLEX_HOUSE_DEEP_CLEANING_SERVICES.jpeg', 'serviceDescription': `Duplex House Deep Cleaning Services: Experience the ultimate clean with our duplex house deep cleaning services. We go beyond the surface to remove dirt, dust, and allergens, ensuring every inch of your home is thoroughly cleaned and sanitized.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'INTERIOR DESIGN', 'serviceTitle': 'WALL DESIGNING SERVICESS', 'serviceName': 'INTERIOR DESIGN SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/WALL_DESIGNING_SERVICESS.jpeg', 'serviceDescription': `Wall Designing Services: Transform your walls into works of art with our designing services. From paint colors to wallpaper patterns, we help you create a personalized and stylish backdrop that complements your décor and reflects your personality.
`}
,{'id': v4uuid(), 'serviceCategory': 'INTERIOR DESIGN', 'serviceTitle': 'GLASS FIXING SERVICES', 'serviceName': 'INTERIOR DESIGN SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/CUPBOARD_DESIGNING_SERVICES.jpeg', 'serviceDescription': 'Innovative Glass fixing solutions provided.'}

    ,{'id': v4uuid(), 'serviceCategory': 'INTERIOR DESIGN', 'serviceTitle': 'FOYER AREA DESIGNING SERVICES', 'serviceName': 'INTERIOR DESIGN SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/FOYER_AREA_DESIGNING_SERVICES.jpeg', 'serviceDescription': `Foyer Area Designing Services: Make a lasting impression with a beautifully designed foyer area. Our expert designers create welcoming entryways that set the tone for your home or business, combining functionality with aesthetics to create a memorable space.
`}
    ,{'id': v4uuid(), 'serviceCategory': 'INTERIOR DESIGN', 'serviceTitle': 'TV UNIT DESIGNING SERVICES', 'serviceName': 'INTERIOR DESIGN SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/TV_UNIT_DESIGNING_SERVICES.jpeg', 'serviceDescription': `TV Unit Designing Services: Elevate your entertainment experience with a custom-designed TV unit. Our designers craft stylish and functional units that provide ample storage and display space while seamlessly integrating with your living room décor.`}
    ,{'id': v4uuid(), 'serviceCategory': 'INTERIOR DESIGN', 'serviceTitle': 'CUPBOARD DESIGNING SERVICES', 'serviceName': 'INTERIOR DESIGN SERVICES', 'serviceImg': 'https://raw.githubusercontent.com/sujith133/maxCleanz/main/subServices/CUPBOARD_DESIGNING_SERVICES.jpeg', 'serviceDescription': `Cupboard Designing Services: Maximize storage and organization with our cupboard designing services. Whether it's for your bedroom, kitchen, or office, our designers create customized solutions that meet your specific needs and enhance the functionality of your space.`}];
    
    
    

    const { id } = useParams();
        console.log('ID:', id);
    return(
        <div className='serviceDetailsContainer margin'>
            <h1 className='titleHeading'>{id}</h1>
            <div className='detailedContainer'>
                {subServicesList.filter(each=>(each.serviceTitle===id)).map(item=>(
                    <div>
                    <p>{item.serviceDescription}</p>
                    
                    <img className='detailedImage' src={item.serviceImg} alt="dummy" />
                    
                    {item.serviceCategory==="KEEPING"?(<form className="bookingForm" onSubmit={(event)=>sendRequest(event)}>
                <h1>Booking for any AMC'S for <strong>{id}</strong></h1>
                <label htmlFor="name">Your Name</label>
                <input className="formInput" onChange={(event)=>onNameChange(event)} value={name} id="name" type="text" placeholder="Enter Your Name" />
                <label htmlFor="email">Your Mail</label>
                <input className="formInput" onChange={(event)=>onMailChange(event)} value={email} id="email" type="email" placeholder="Enter Your Email" />
                <label htmlFor="number">Your Number</label>
                <input className="formInput" onChange={(event)=>onNumberChange(event)} value={number} id="number" type="number" placeholder="Enter Your Phone Number" />
                <label htmlFor="area">Your Area</label>
                <input className="formInput" onChange={(event)=>onAreaChange(event)} value={area} id="area" type="number" placeholder="Enter Required square feet area for the service" />
                <label htmlFor="tenure">Your Tenure</label>
                <input className="formInput" onChange={(event)=>onTenureChange(event)} value={tenure} id="tenure" type="number" placeholder="Enter Required Tenure for the service" />
                <button className="formSubmit bookingButton" type="submit" >Book Now</button>
            </form>):(<form className="bookingForm" onSubmit={(event)=>sendRequest(event)}>
                <h1>Booking for the <strong>{id}</strong></h1>
                <label htmlFor="name">Your Name</label>
                <input className="formInput" onChange={(event)=>onNameChange(event)} value={name} id="name" type="text" placeholder="Enter Your Name" />
                <label htmlFor="email">Your Mail</label>
                <input className="formInput" onChange={(event)=>onMailChange(event)} value={email} id="email" type="email" placeholder="Enter Your Email" />
                <label htmlFor="number">Your Number</label>
                <input className="formInput" onChange={(event)=>onNumberChange(event)} value={number} id="number" type="number" placeholder="Enter Your Phone Number" />
                <button className="formSubmit bookingButton" type="submit" >Book Now</button>
            </form>)}
                    </div>
                ))

                }
                

            </div>
            
        </div>
    )
}

export default SubServiceDetails